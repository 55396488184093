<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>

      <v-card-actions class="pl-8 pt-8 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Providers' }">Back</v-btn>
        <v-btn color="error" :disabled="!deactivateEnabled" :loading="deactivating" @click="deactivate()">Deactivate</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showDeactivated">Deactivated</v-btn>
        </v-fade-transition>

      </v-card-actions>

      <v-card-text>
        <v-container fluid>

          <v-row>
            <v-col>
               <NKWsByProviderTable :providerID="id" :updateKey="nkwBookingsTableKey"></NKWsByProviderTable>
            </v-col>

          </v-row>

        </v-container>
      </v-card-text>

    </v-card>

  </v-container>
</template>

<script>
import auth from '@/auth'
import formsMixin from '@/mixins/forms'

export default {
  name: 'ProviderDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        name: null,
        is_protected: null,
      },
      loading: false,
      deactivating: false,
      showDeactivated: false,
      nkwBookingsTableKey: 'init',
    }
  },

  computed: {
    deactivateEnabled () {
      if (this.item.is_protected) {
        if (auth.hasPermission('SEA Senior') ||  auth.hasPermission('Tech')) {
          return true
        }
      } else {
        if (auth.hasPermission('SEA Basic') || auth.hasPermission('SEA Senior') ||  auth.hasPermission('Tech')) {
          return true
        }
      }
      return false
    },
    itemEndpoint () {
      return '/a/core/providers/' + this.id
    },
    pageTitle () {
      return this.item.name ? this.item.name : 'Provider'
    }
  },

  components: {
    NKWsByProviderTable: () => import('@/components/tables/NKWsByProviderTable'),
  },

  mixins: [formsMixin],

  methods: {
    deactivate: function () {
      if (!confirm('Are you sure? This will soft delete all negative keyword bookings shown below.')) {
        return
      }
      
      this.deactivating = true
      var myURL = this.itemEndpoint + '/deactivate'
      this.$http.put(myURL).then(resp => {
        this.nkwBookingsTableKey = this.$dateTime.now().toString()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.deactivating = false)
    },

    fetchItem: function () {
      this.loading = true

      var myURL = this.itemEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },
  },

  created: function () {
    this.fetchItem()
  },
}
</script>

<style scoped>
</style>